<template>
  <div class="PageBox">
    <div class="header_bg">
      <div style="text-align: right;font-size: 16px;padding: 5px">

      </div>
      <div class="logo">
        {{$t('login.text[2]')}}
      </div>
    </div>
      <div class="loginpanel">
        <div class="header0">
          <div class="MyHeader2">
            <img class="head"
            :src="header.includes('head_')?`./static/head/${header}`:header"/>
          </div>
          <span style="padding-left:15px;font-size:20px">{{postData.username}}</span>
        </div>
        <div>
          <van-field
            type="text"
            autocomplete="off"
            style="height: 0; width: 0; padding: 0; position: absolute"
          />
          <van-field
            type="password"
            autocomplete="off"
            style="height: 0; width: 0; padding: 0; position: absolute"
          />
          <van-field
            :border="false"
            class="input"
            v-model.trim="postData.password"
            :type="showPass ? 'text' : 'password'"
            :placeholder="$t('login.placeholder[1]')"
            size="large"
            clearable
            autocomplete="off"
            :right-icon="showPass ? 'eye' : 'closed-eye'"
            @click-right-icon="showPass = !showPass"
          />
        </div>
        <div class="centerPanel" style="margin-top: 30px">
          <van-button class="van-button4" @click="onSubmit()">{{ $t('login1[1]') }}</van-button>
          <div style="margin: 0 auto;text-align: center">

          </div>
          <div style="margin: 10px auto;text-align: left;margin-left: 35px">
            <router-link class="href" to="/resetpwd"><span>{{
              $t('login.default[2]')
              }}</span></router-link>
          </div>
        </div>
    </div>
  </div>
</template>
<script>
  import Service from "@/common/Service";

export default {
  name: "Login",
  components: {},
  props: ["username","header"],
  data() {
    return {
      header:'',
      postData: {
        username: localStorage["UserName"] || "",
        password: localStorage["PassWord"] || "",
        uniqueID: localStorage["phoneId"] || "", //手机唯一标识
      },
      keepUser: localStorage["KeepUser"] == "1" ? true : false,
      isSubmit: false,
      showPass: false,
      language1: localStorage.getItem("Language") == "cn",
    };
  },
  computed: {},
  watch: {},
  created() {
    this.isSubmit = false;
    console.log(this.language1);
  },
  mounted() {
    var query= this.$route.query;
    if (query["username"]){
      this.postData.username = query["username"];
    }
    if (query["header"]){
      this.header = query["header"];
    }
  },
  activated() {},
  destroyed() {},
  methods: {
    onSubmit() {
      if (!this.postData.username) {
        this.$Dialog.Toast(this.$t("login.placeholder[0]"));
        return;
      }
      if (!this.postData.password) {
        this.$Dialog.Toast(this.$t("login.placeholder[1]"));
        return;
      }
      this.isSubmit = true;
      this.$Model.Login(this.postData, (data) => {
        this.isSubmit = false;
        if (data.code==0){
          let idx = data.code_dec;
          let msg = this.$t("login.codes['"+idx+"']");
          this.$toast(msg);
          return;
        }else if (data.code==2){
          var msg = this.$t("login3[0]");
          this.$toast(msg);
          return;
        }
        var appData = {};
        try {
          var data = localStorage["AppData"];
          if (data) {
            appData = JSON.parse(data);
          }
        } catch (e) {
          // alert('3'+e.message)
          appData = {};
        }
        if (this.keepUser) {
          appData["UserName"] = this.postData.username;
          appData["PassWord"] = this.postData.password;
          localStorage["UserName"] = this.postData.username;
          localStorage["PassWord"] = this.postData.password;
        } else {
          appData["UserName"] = null;
          appData["PassWord"] = null;
          localStorage.removeItem("UserName");
          localStorage.removeItem("PassWord");
        }
        var data = JSON.stringify(appData);
        localStorage["AppData"] = data;
        var ret = this.$Util.writeAppStorage(data);
        this.$router.push("/");
      });
    },
    changeKeepUser() {
      var appData = {};
      try {
        var data = localStorage["AppData"];
        if (data) {
          appData = JSON.parse(data);
        }
      } catch (e) {
        // alert('3'+e.message)
        appData = {};
      }
      if (this.keepUser) {
        appData["KeepUser"] = 1;
        localStorage["KeepUser"] = 1;
      } else {
        appData["KeepUser"] = 0;
        localStorage.removeItem("KeepUser");
      }
      var data = JSON.stringify(appData);
      localStorage["AppData"] = data;
      this.$Util.writeAppStorage(data);
    },
    // 用户名格式化不能输入空格
    formatterUsername(value) {
      return value.replace(/\s*/g, "");
    },
    gotoApp(){
      window.location.href = this.InitData.setting.app_url;
    },
    doShow2(){
      _MEIQIA('showPanel');
    },
  },
};
</script>
<style scoped>
.LoginHeader {
  padding: 0 0;
}

.PageBox {
  padding: 0;

  background-size: cover;
}
.ScrollBox {

}
.header0 {
  display:flex;
  font-size: 17px;
  padding-left: 20px;
  line-height: 55px;
  margin: 0px auto;
  padding-top: 10px;
}
.MyHeader2 {
  font-size: 19px;
  border-radius: 50%;
  background: pink;
  overflow: hidden;
  width: 57px;
  height: 57px;
}
.MyHeader2 img {
  width: 100%;
}
.loginpanel {
  height:300px;
  margin: 15px;
  margin-top: 0px;
  border-radius: 10px;
  background-color: #225FB4;
  background-size: cover;
  /*background-color: #191C23;*/
  padding-bottom: 60px;
  padding-top: 10px;
  border: 2px solid #749DB9;
  opacity: 0.9;
}

.logo {
  margin: 20px auto;
  margin-left: 50px;
  line-height: 100px;
  font-size: 32px;
  font-weight: bolder;
  color: var(--font_nav_bar);
}

.PageBox a:link {
  color: #635327;
}

.header_bg {
  color: var(--font_subtitle);
  height: 170px;
}

.van-cell--borderless.input {
  width: 95%;
  background-color: #2B3058;
  border-radius: 5px;
  overflow: hidden;
  margin-top: 25px;
  margin-left: 10px;
  padding: 0 30px 0 13px;
  height: 45px;
}
.van-cell >>> .van-field__body {
  height: 100%;
}
.van-cell >>> .van-icon-closed-eye {
  font-size: 19px;
  color:  var(--cell_placeholder);
  margin-top: 5px;
}
.van-cell--borderless >>> .van-cell__value--alone {
  height: 100%;
}


.van-cell--borderless.input >>> .van-field__control {
  color: var(--cell_title) !important;
  padding-left: 7px;
  height: 100%;
  font-size: 18px;
}

.van-cell--borderless.input >>> .van-field__control::-webkit-input-placeholder {
  color: var(--cell_placeholder);
  font-size: 19px;

}

.van-cell--borderless.input >>> .van-field__control::-moz-placeholder {
  color: var(--cell_title);
  font-size: 19px;

}

.van-cell--borderless.input >>> .van-field__control::-ms-input-placeholder {
  color: var(--cell_placeholder);
  font-size: 18px;

}

.van-form {
  padding: 0 35px;
}

.van-button--danger {
  max-width: 200px;
  margin: auto;

  font-size: 20px !important;
  font-weight: bold;
  background: linear-gradient(90deg, #fff, #fff);
  color: #4e51bf;
}

.goRe a {
  color: #fe889d;
}

.van-cell--borderless >>> .van-icon-manager,
.van-cell--borderless >>> .van-icon-lock {
  color: #ccc !important;
}

.van-cell--borderless >>> .van-icon-manager::before,
.van-cell--borderless >>> .van-icon-lock::before {
  font-size: 27px;
  margin-top: 4px;
}

.van-cell--large {
  padding-top: 17px;
  padding-bottom: 8px;
}

.Site .van-nav-bar >>> .van-icon {
  color: #333;
}

.van-nav-bar__text1 >>> .van-nav-bar__text {
  letter-spacing: 0.09rem;
  color: #000000;
}

.PageBox >>> .letter_s {
  letter-spacing: 0.08rem;
}
.goRe {
  margin-top: 20px;

  font-size: 15px;
  text-align: left;
  color: #4e51bf;
}

.btn {
  width: 95%;
  padding: 10px 50px;
  border-radius: 5px;
  background-color: var(--button_bg);
  color: #FFFEFF;
  font-size: 18px;
  text-align: center;
  margin: 15px 10px 30px;
}
  .btn2{
    width: 90%;
    text-align: center;
  }
</style>
